// extracted by mini-css-extract-plugin
export var productDiscoverTestimonialSection = "w_cN";
export var productDiscoveryAchievementsSection = "w_cZ";
export var productDiscoveryBenefitsSection = "w_cM";
export var productDiscoveryCaseSection = "w_cV";
export var productDiscoveryClientSection = "w_c0";
export var productDiscoveryIndustriesSection = "w_cL";
export var productDiscoveryOverviewSection = "w_cS";
export var productDiscoveryProcessSection = "w_cR";
export var productDiscoveryResultsSection = "w_cW";
export var productDiscoveryRolesSection = "w_cT";
export var productDiscoveryServicesSection = "w_cK";
export var productDiscoveryStagesSection = "w_cP";
export var productDiscoverySuccessStoriesSection = "w_cX";
export var productDiscoveryWhyChooseSection = "w_cY";
export var productDiscroveryImportanceSection = "w_cQ";