// extracted by mini-css-extract-plugin
export var productDiscoverySession__card = "dp_Br";
export var productDiscoverySession__cardFour = "dp_Bw";
export var productDiscoverySession__cardOne = "dp_Bs";
export var productDiscoverySession__cardThree = "dp_Bv";
export var productDiscoverySession__cardTwo = "dp_Bt";
export var productDiscoverySession__cardWrapper = "dp_Bq";
export var productDiscoverySession__description = "dp_Bz";
export var productDiscoverySession__header = "dp_Bp";
export var productDiscoverySession__image = "dp_BB";
export var productDiscoverySession__info = "dp_Bx";
export var productDiscoverySession__title = "dp_By";